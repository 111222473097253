import {getLastCoordinates} from "./map";
import {fromLonLat} from "ol/proj";

export function prepareLocation() {
  return {
    location: {
      user: getLastCoordinates(),
      zoom: 14,
      default: true,
      updatedTime: Date.now()
    }
  }
}

export function getLocation(position: GeolocationPosition) {
  if (position) {
    return {
      user: fromLonLat([position.coords.longitude, position.coords.latitude]),
      zoom: 12,
      default: false,
      updatedTime: Date.now()
    }
  }

  return prepareLocation();
}