import {Modal} from "react-bootstrap";
import styles from "../GreetingsModal/GreetingsModal.module.css";
import classNames from "classnames";
import lighting from "../../img/light-lamp.svg";
import marker from "../../img/marker-demo.svg";
import React, {useContext, useEffect, useState} from "react";
import Select from 'react-select';
import {ONBOARDING_DONE_STATUS, setOnboardingStatus} from "../../../service/onboarding";
import {isShow} from "../../../model/modal";
import {getAllCities} from "../../../service/client/cities-service";
import {City} from "../../../model/city";
import Skeleton from "react-loading-skeleton";
import {fromLonLat} from "ol/proj";
import {ApplicationContext} from "../../../model/context";
import {sendStatsEvent} from "../../../service/client/stats-service";
import {GREETINGS_SELECT_CITY_EVENT} from "../../../model/event";

export const GreetingsModal = (props: any) => {
  let [applicationContext, setApplicationContext] = useContext(ApplicationContext);
  let [isCitySkeletonVisible, setIsCitySkeletonVisible] = useState<boolean>(true);
  let [selectedCity, setSelectedCity] = useState<any>({})
  let [options, setOptions] = useState([]);

  let onOptionCitiesChange = (city: any) => {
    setSelectedCity(city);
  }

  let handleDoneClick = () => {
    let context = {...applicationContext}
    context.location = {
      default: false,
      updatedTime: Date.now(),
      zoom: 12,
      user: fromLonLat([Number(selectedCity.lon), Number(selectedCity.lat)])
    };

    setApplicationContext(context);
    setOnboardingStatus(ONBOARDING_DONE_STATUS);

    // костыль, из-за data[0] - нужно убрать
    sendStatsEvent(GREETINGS_SELECT_CITY_EVENT, selectedCity.id || selectedCity?.value?.id);

    props.onHide();
  }

  useEffect(() => {
    if (isShow(props)) {
      getAllCities()
        .then((data: any) => {
          setOptions(data.map((city: any) => {
            let processedCity: City = {
              lon: city.lon,
              lat: city.lat,
              label: city.label,
              value: {id: city.id, name: city.label}
            };
            return processedCity;
          }));
          setSelectedCity(data[0]);
        })
        .catch((e: Error) => console.log(e))
        .finally(() => setIsCitySkeletonVisible(false));
    }
  }, [props.show]);

  return (
    <Modal
      {...props}
      aria-labelledby={props.identifier}
      dialogClassName={styles.GreetingsModalDialog}
      contentClassName={styles.GreetingsModalDialogContent}
    >
      <Modal.Title className={classNames(styles.GreetingsModalDialogTitle)} id={props.identifier}>
        <div className={classNames('d-flex', 'flex-row', 'justify-content-between')}>
          <div className={classNames('d-flex', 'flex-row')}>
            <div>
              <img src={lighting} style={{width: '27px', marginTop: '2px', marginLeft: '6px'}} alt={'Lighting'}/>
            </div>
            <div>
              <p style={{ fontSize: '13px', fontWeight: 'bold', color: '#ffffff', marginLeft: '8px', marginTop: '11px'}}>Первый раз?</p>
            </div>
          </div>
        </div>
      </Modal.Title>
      <Modal.Body style={{ padding: '0px' }}>
        <div style={{paddingBottom: '14px'}}>
          <div style={{padding: '9px 0px 0px 0px', textAlign: 'center'}}>
            <div>
              <p className={'fs-13'}>
                Кликайте по меткам на карте и читайте наши истории
              </p>
            </div>
            <div style={{ marginTop: '-5px'}}>
              <img src={marker}/>
            </div>
          </div>

          <div style={{marginTop: '15px'}}>
            <div style={{ textAlign: 'center' }}>
              <p className={'fs-13'}>Какой город посмотреть первым ?</p>
            </div>
            {isCitySkeletonVisible &&
              <div style={{ textAlign: 'center' }}>
                <Skeleton count={1} style={{width: '240px', height: '34px', margin: '0 auto' }} />
              </div>
            }
            {!isCitySkeletonVisible &&
              <div className={styles.GreetingsSelectContainer}>
                <Select
                    onChange={onOptionCitiesChange}
                    defaultValue={options[0]}
                    options={options}
                />
              </div>
            }

          </div>

          <div style={{marginTop: '20px', textAlign: 'center'}}>
            <button className={'secondary-action-button'} onClick={handleDoneClick}>
              <div style={{paddingLeft: '40px', paddingRight: '40px'}}>
                OK
              </div>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}