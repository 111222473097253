import {client} from "../../index";
import {getFingerprint} from "../identity";

export function sendStatsEvent(eventType: string, value?: string) {
  client({
    method: "POST",
    url: "/v1/stats/event",
    data: {
      eventType: eventType,
      fingerprint: getFingerprint(),
      value: value
    },
  }).then((r) => r).catch((e: Error) => e);
}