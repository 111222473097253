import {LOCAL_STORAGE_LAST_COORDINATES_KEY, LOCAL_STORAGE_LAST_ZOOM_KEY} from "./storage";
import {fromLonLat} from "ol/proj";
import {Coordinate} from "ol/coordinate";

export function setLastCoordinates(coordinates: []) {
  localStorage.setItem(LOCAL_STORAGE_LAST_COORDINATES_KEY, JSON.stringify(coordinates));
}

export function setZoom(zoom: number) {
  localStorage.setItem(LOCAL_STORAGE_LAST_ZOOM_KEY, String(zoom));
}

export function getLastCoordinates(): Coordinate {
  let data = localStorage.getItem(LOCAL_STORAGE_LAST_COORDINATES_KEY) || '';
  if (data) {
    let obj: [] = JSON.parse(data.toString());
    return obj;
  } else {
    return fromLonLat([37.617698, 55.755864]);
  }
}

export function getZoom(): number {
  return Number(localStorage.getItem(LOCAL_STORAGE_LAST_ZOOM_KEY)) || 13;
}

