import {client} from "../../index";
import {Account} from "../../model/account";
import {getToken, setAccount} from "../authorization";

export function initializeAccount() {
  return client({
    method: "POST",
    url: "/v1/account/current",
    data: {
      token: getToken(),
    },
  }).then((r:any) => {
    let account: Account = r.data;
    setAccount(account);
    return account;
  }).catch((e: Error) => {
      console.log('Error while for processing initialize account')
      return e;
  })
}