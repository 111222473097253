import {Modal, Toast, ToastContainer} from "react-bootstrap";
import classNames from "classnames";
import close from "../../img/close-blue.svg";
import copy from "../../img/copy-blue.svg";
import share from "../../img/share-blue.svg";
import logo from "../../img/logo.png";
import like from "../../img/like.svg";
import dislike from "../../img/dislike.svg";
import closeRed from "../../img/close-red.svg";
import view from "../../img/view.svg";
import about from "../../img/about.svg";
import space from "../../img/ufo.svg";
import React, {useEffect, useState} from "react";
import Avatar from "react-avatar";
import Skeleton from "react-loading-skeleton";
import {isShow} from "../../../model/modal";
import {getStory, storyDislike, storyLike} from "../../../service/client/stories-service";
import {Story} from "../../../model/story";
import moment from "moment";
import styles from "./StoryModal.module.css";
import {useSearchParams} from "react-router-dom";
import {copyToClipboard, STORY_PARAM} from "../../../model/url";
import {sendStatsEvent} from "../../../service/client/stats-service";
import {
  CLOSE_STORY_MODAL_EVENT,
  LOCATION_ACTION_SELECT_CITY_DONE_EVENT,
  MODAL_CARD_COPY_ACTION_EVENT,
  MODAL_CARD_SHARE_ACTION_EVENT,
  OPEN_STORY_MODAL_BY_MARKER_EVENT
} from "../../../model/event";
import {setCustomTitle, setDefaultTitle} from "../../../service/site";

export const StoryModal = (props: any) => {
  let [isSkeletonVisible, setSkeletonVisible] = useState<boolean>(true);
  let [copyToastShow, setCopyToastShow] = useState(false);
  let [isGrade, setGrade] = useState<boolean>(false);

  let [, setSearchParams] = useSearchParams();
  let [story, setStory] = useState<Story>();

  let handleLoad = () => {};

  let handleCloseModalDialog = () => {
    if (copyToastShow) {
      setCopyToastShow(false);
      setTimeout(() => props.onHide(), 250);
    } else {
      props.onHide();
    }

    setSearchParams((params) => {
      params.delete(STORY_PARAM);
      return params;
    })

    sendStatsEvent(CLOSE_STORY_MODAL_EVENT);
    setDefaultTitle();
  }

  let showMainContent = () => {
    setSkeletonVisible(false);
  }

  let copyLink = () => {
    sendStatsEvent(MODAL_CARD_COPY_ACTION_EVENT);
    copyToClipboard(window.location.href);
    setCopyToastShow(true);
  }

  let shareStory = () => {
    window.open(`https://telegram.me/share/url?url=${window.location.href}&text=${story?.storyLabel}`);
    sendStatsEvent(MODAL_CARD_SHARE_ACTION_EVENT);
  };

  let reward = (type: 'like' | 'dislike') => {
    if (!isGrade) {
      let updatedStory = {...story}

      if (type === 'like' && updatedStory.likes && updatedStory.id) {
        updatedStory.likes += 1;
        storyLike(updatedStory.id)
      }

      if (type === 'dislike' && updatedStory.dislikes && updatedStory.id) {
        updatedStory.dislikes += 1;
        storyDislike(updatedStory.id)
      }

      // @ts-ignore
      setStory(updatedStory);
      setGrade(true);
    }
  }

  useEffect(() => {
    if (isShow(props)) {
      setSkeletonVisible(true);

      let storyId = props.data.id;

      sendStatsEvent(OPEN_STORY_MODAL_BY_MARKER_EVENT, storyId);

      getStory(storyId)
        .then((response) => {
          let responseStory: Story = response.data;
          setStory(responseStory);
          setTimeout(() => showMainContent(), 200);
          setCustomTitle(responseStory.storyLabel);
        })
        .catch((e: Error) => {
          console.error('Error while for getting story')
          console.error(e);
          setTimeout(() => props.onHide(), 450);
        })
        .finally(() => {
          setGrade(false);
        })
    }
  }, [props.show]);

  return (
    <Modal
      {...props}
      aria-labelledby={props.identifier}
      dialogClassName={styles.StoryModalDialog}
      contentClassName={styles.StoryModalDialogContent}
      onHide={handleCloseModalDialog}
    >
      <ToastContainer className={styles.StoryCopyToast}>
        <Toast onClose={() => setCopyToastShow(false)} show={copyToastShow} delay={2200} bg={'dark'} animation={true}>
          <Toast.Body style={{color: '#FFFFFF'}} className={'raleway'}>
            <div style={{display: 'flex'}}>
              <div>
                <img src={about} style={{ width: '24px'}}/>
              </div>
              <div style={{marginLeft: '12px', marginTop: '2px' }}>
                Ссылка успешно скопирована
              </div>
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {isSkeletonVisible &&
        <div>
          <div className={classNames(styles.SkeletonHeaderModalDialogContainer)}>
            <div className={classNames('d-flex', 'flex-row', 'justify-content-between')}>
              <div style={{marginTop: '5px'}}>
                  <div style={{width: '74px'}}>
                      <div style={{ width: '40px', marginTop: '4px', height: '45px', minWidth: '40px'}} onClick={() => handleCloseModalDialog()}>
                          <img src={close} alt="close" style={{ marginLeft: '14px', marginTop: '6px', minWidth: '15px'}} onLoad={handleLoad}/>
                      </div>
                  </div>
              </div>
              <div>
                  <Skeleton count={1} style={{width: '130px', height: '14px', marginTop: '21px'}}></Skeleton>
              </div>
              <div className={'d-flex flex-row'} style={{marginTop: '7px', marginRight: '3px'}}>
                  <div>
                      <Skeleton count={1} style={{width: '32px', height: '32px'}}></Skeleton>
                  </div>
                  <div style={{marginLeft: '5px', marginRight: '5px'}}>
                      <Skeleton count={1} style={{width: '32px', height: '32px'}}></Skeleton>
                  </div>
              </div>
            </div>
          </div>
          <div className={'d-flex flex-row ml-13 mt-7 justify-content-between'}>
            <div className={'d-flex flex-row'}>
              <div>
                  <Skeleton circle={true} style={{width: '40px', height: '40px'}}></Skeleton>
              </div>
              <div className={'ml-6'}>
                  <div style={{marginTop: '2px'}}>
                      <Skeleton count={1} style={{width: '130px', height: '12px'}}></Skeleton>
                  </div>
                  <div style={{marginTop: '-4px'}}>
                      <Skeleton count={1} style={{width: '110px', height: '12px'}}></Skeleton>
                  </div>
              </div>
            </div>
            <div>
              <div onClick={() => handleCloseModalDialog()}>
                <div className={classNames(styles.StoryModalDialogSkeletonCloseButton, 'unselectable')}>
                    <img src={closeRed} style={{ width: '8px', marginTop: '-3px' }}/>
                </div>
              </div>
            </div>
          </div>
          <div style={{marginTop: '13px', padding: '5px 14px 8px 14px'}}>
            <div>
              <Skeleton count={4} style={{width: '100%', height: '12px'}}></Skeleton>
            </div>
            <div>
              <Skeleton count={1} style={{width: '100%', height: '12px'}}></Skeleton>
            </div>
          </div>
          <div className={'d-flex flex-row justify-content-between'} style={{marginTop: '2px', padding: '5px 14px 8px 14px'}}>
            <div className={'d-flex flex-row'}>
              <div>
                <Skeleton count={1} style={{width: '50px', height: '32px'}}></Skeleton>
              </div>
              <div className={'ml-8'}>
                <Skeleton count={1} style={{width: '50px', height: '32px'}}></Skeleton>
              </div>
            </div>
            <div>
              <div style={{marginTop: '5px'}}>
                  <Skeleton count={1} style={{width: '50px', height: '20px'}}></Skeleton>
              </div>
            </div>
          </div>
        </div>
      }
      {!isSkeletonVisible &&
        <Modal.Title className={classNames(styles.StoryModalDialogTitle)} id={props.identifier}>
            <div className={classNames('d-flex', 'flex-row', 'justify-content-between')}>
                <div style={{width: '74px'}}>
                    <div style={{ width: '40px', marginTop: '2px', height: '45px', minHeight: '45px'}} onClick={() => handleCloseModalDialog()}>
                        <img src={close} alt="close" style={{ marginLeft: '14px', marginTop: '6px', minWidth: '15px'}} onLoad={handleLoad}/>
                    </div>
                </div>
                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                    <div className={'fs-15'}>{story?.storyLabel}</div>
                </div>
                <div className={classNames('d-flex', 'flex-row')} style={{width: '74px', marginTop: '11px', marginRight: '4px'}}>
                    <div onClick={copyLink}>
                        <div className={styles.SecondaryActionButtonIcon}>
                            <img src={copy} alt={'copy'}/>
                        </div>
                    </div>
                    <div style={{marginLeft: '5px'}} onClick={() => shareStory()}>
                        <div className={styles.SecondaryActionButtonIcon}>
                            <img src={share} alt={'share'} style={{marginTop: '2px'}}/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Title>
      }
      {!isSkeletonVisible &&
        <Modal.Body style={{ padding: '0px', border: '0' }}>
            <div className={styles.StoryModalDialogDivider}><hr/></div>
            <div style={{paddingBottom: 0}}>
                <div className={'d-flex flex-row justify-content-between'}>
                    <div className={styles.StoryUserProfileBlock}>
                        <div className={'d-flex flex-row'}>
                            <div>
                                <Avatar value="А" color={'black'} size="37" round={true} className={'bold'}/>
                            </div>
                            <div style={{marginTop: '-4px', marginLeft: '9px'}}>
                                <div>
                                    <span className={'fs-13'} style={{ color: 'rgba(7, 121, 226, 0.94)', fontWeight: 500}}>{story?.userLabel}</span>
                                </div>
                                <div style={{ marginTop: '-6px'}}>
                                    <span className={'fs-11'} style={{color: '#747474'}}>{moment(story?.createdAt).format('DD MMMM YYYY в HH:mm:ss')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classNames('d-flex', 'flex-row', styles.StoryModalDialogActionButtonsContainer)}>
                        <div onClick={copyLink}>
                            <div className={styles.SecondaryActionButtonIcon}>
                                <img src={copy} alt={'copy'} onLoad={handleLoad} />
                            </div>
                        </div>
                        <div style={{marginLeft: '5px'}} onClick={() => shareStory()}>
                            <div className={styles.SecondaryActionButtonIcon}>
                                <img src={share} alt={'share'} style={{marginTop: '2px'}} onLoad={handleLoad}/>
                            </div>
                        </div>
                        <div onClick={() => handleCloseModalDialog()}>
                            <div className={classNames(styles.StoryModalDialogCloseButton, 'unselectable')}>
                                <img src={closeRed} style={{ width: '8px', marginTop: '-3px' }} onLoad={handleLoad}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{padding: '0px 18px 0px 18px'}}>
                    <div style={{marginTop: '11px'}}>
                        <p className={'fs-13'} style={{textAlign: 'justify'}}>
                          {story?.text}
                        </p>
                    </div>
                    <div className={styles.StoryCityBlock}>
                        <div className={'d-flex flex-row'}>
                            <div>
                                <img src={logo} alt={'Лого'} onLoad={handleLoad}/>
                            </div>
                            <div>
                                <p className={'fs-13'}>{story?.place}</p>
                            </div>
                        </div>
                    </div>
                  {story?.rootComment &&
                    <div className={classNames('d-flex', 'flex-row')} style={{padding: '4px 0px'}}>
                        <div className={styles.StoryUFOIcon}>
                            <img src={space} style={{width: '15px'}} />
                        </div>
                        <div style={{marginTop: '6px', marginLeft: '8px'}}>
                            <div className={'fs-11'}><span style={{fontWeight: 500}}>Комментарий от UFO: </span> <span style={{marginLeft: '3px', color: '#747474'}}>{story?.rootComment}</span></div>
                        </div>
                    </div>
                  }
                </div>
                <div className={styles.StoryCityBottomDividerContainer}><hr/></div>
                <div style={{marginTop: '-10px', marginLeft: '10px'}}>
                    <div className={'d-flex justify-content-between'}>
                        <div className={'d-flex flex-row'}>
                            <div className={classNames('d-flex', 'flex-row', styles.GradeButtonIcon)} onClick={() => reward('like')}>
                                <div>
                                    <img src={like} className={'unselectable'} style={{width: '24px', marginTop: '5px', marginLeft: '5px'}} onLoad={handleLoad}/>
                                </div>
                                <div style={{marginTop: '8px', marginLeft: '2px'}}>
                                    <span className={'fs-13 unselectable'} >{story?.likes}</span>
                                </div>
                            </div>
                            <div className={classNames('d-flex', 'flex-row', styles.GradeButtonIcon)} style={{marginLeft: '4px'}} onClick={() => reward('dislike')}>
                                <div>
                                    <img src={dislike} className={'unselectable'} style={{width: '24px', marginTop: '5px', marginLeft: '5px'}} onLoad={handleLoad}/>
                                </div>
                                <div style={{marginTop: '8px', marginLeft: '2px'}}>
                                    <span className={'fs-13 unselectable'} >{story?.dislikes}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{marginRight: '10px', marginTop: '5px'}}>
                            <div className={'d-flex flex-row'}>
                                <div>
                                    <img src={view} onLoad={handleLoad}/>
                                </div>
                                <div>
                                    <span className={'fs-13'} style={{fontWeight: 500, color: 'rgba(12, 74, 96, 0.82)', marginLeft: '6px'}}>{story?.views}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: '-4px'}}><hr className={styles.StoryBottomContainer}/></div>
            </div>
        </Modal.Body>
      }
    </Modal>
  );
}