export const SUPPORT_REQUEST_MODAL = 'support_request_modal';
export const SHARE_STORY_MODAL = 'share_story_modal';
export const GREETINGS_MODAL = 'greetings_modal';
export const GEO_LOCATION_MODAL = 'geo_location_modal';
export const STORY_MODAL = 'story_modal';

export function prepareModal(modal: string, data?: {}) {
  return {modal: [modal], data: data, updateTime: Date.now()};
}

export function clearedModal() {
  return {modal: [''], updateTime: Date.now()};
}

export function isShow(props: any) {
  return props.show === true;
}