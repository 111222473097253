import {Account} from "../model/account";
import {LOCAL_STORAGE_ACCOUNT_KEY} from "../model/storage";

export function setAccount(account: Account): void {
  localStorage.setItem(LOCAL_STORAGE_ACCOUNT_KEY, JSON.stringify(account));
}

export function getAccount(): Account {
  let json: string = localStorage.getItem(LOCAL_STORAGE_ACCOUNT_KEY) || '';
  return json ? JSON.parse(json) : null;
}

export function getToken(): string {
  let account = getAccount();

  if (account) {
    return account.token
  }

  return '';
}