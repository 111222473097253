import megaphone from '../../img/megaphone.svg'
import telegram from '../../img/telegram.svg'
import close from '../../img/close-white.svg'
import styles from './ShareStoryModal.module.css';
import classNames from "classnames";
import {Modal} from "react-bootstrap";
import React, {useEffect} from "react";
import {isShow} from "../../../model/modal";
import {sendStatsEvent} from "../../../service/client/stats-service";
import {
  CLOSE_SHARE_STORY_MODAL_EVENT,
  CREATE_STORY_ACTION_EVENT,
  OPEN_SHARE_STORY_MODAL_EVENT
} from "../../../model/event";

export const ShareStoryModal = (props: any) => {
  let onImageLoad = () => {};

  let handleCloseModal = () => {
    sendStatsEvent(CLOSE_SHARE_STORY_MODAL_EVENT);
    props.onHide();
  }

  let handleWriteStoryClick = () => {
    window.open('https://t.me/utales_bot', '_blank');
    sendStatsEvent(CREATE_STORY_ACTION_EVENT);
  }

  useEffect(() => {
    if (isShow(props)) {
      sendStatsEvent(OPEN_SHARE_STORY_MODAL_EVENT)
    }
  });

  return (
    <Modal
      {...props}
      aria-labelledby={props.identifier}
      dialogClassName={styles.ShareStoryModalDialog}
      contentClassName={styles.ShareStoryModalDialogContent}
    >
      <Modal.Title className={classNames(styles.ShareStoryModalDialogTitle)} id={props.identifier}>
        <div className={classNames('d-flex', 'flex-row', 'justify-content-between')}>
          <div className={classNames('d-flex', 'flex-row')}>
            <div>
              <img src={megaphone} style={{width: '27px', marginTop: '2px', marginLeft: '6px'}} alt={'Support'} onLoad={onImageLoad}/>
            </div>
            <div>
              <p style={{ fontSize: '13px', fontWeight: 'bold', color: '#ffffff', marginLeft: '8px', marginTop: '11px'}}>Поделиться историей</p>
            </div>
          </div>
          <div className={classNames('inline', 'pointer')} style={{color: 'white', marginRight: '7px', marginTop: '6px'}}>
            <div style={{borderRadius: '25px', background: 'rgba(196, 196, 196, 0.08)', padding: '6px 10px 6px 10px'}} onClick={handleCloseModal}>
              <div style={{fontSize: '12px'}}>
                <img src={close} style={{marginTop: '-2px'}} alt={'Close'} onLoad={onImageLoad}/>
              </div>
            </div>
          </div>
        </div>
      </Modal.Title>
      <Modal.Body style={{ padding: '0px' }}>
        <div style={{textAlign: 'center', paddingBottom: '14px'}}>
          <div style={{padding: '9px 11px 0px 11px'}}>
            <div>
              <p className={'fs-13'}>
                Хотите рассказать историю? Отлично!
              </p>
            </div>
            <div style={{ marginTop: '-5px'}}>
              <div style={{width: '100%', margin: '0 auto'}}>
                <p className={'fs-13'}>
                  Напишите свою историю в наш Telegram-бот
                </p>
              </div>
            </div>
          </div>

          <div style={{marginTop: '20px'}}>
            <button className={'secondary-action-button'} onClick={handleWriteStoryClick}>
              <div className={classNames('d-flex', 'flex-row')}>
                <div>
                  <img src={telegram} style={{width: '22px'}} alt={'Telegram'}/>
                </div>
                <div style={{marginLeft: '6px', marginTop: '2px'}}>
                  <div>Написать историю</div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}