import {LOCAL_STORAGE_ONBOARDING_STATUS_KEY} from "../model/storage";

export const ONBOARDING_FIRST_TIME_VIEW_STATUS= 'ONBOARDING_FIRST_TIME_VIEW_STATUS';
export const ONBOARDING_DONE_STATUS = 'ONBOARDING_DONE_STATUS';

export function setOnboardingStatus(status: 'ONBOARDING_FIRST_TIME_VIEW_STATUS' | 'ONBOARDING_DONE_STATUS') {
  localStorage.setItem(LOCAL_STORAGE_ONBOARDING_STATUS_KEY, status);
}

export function getOnboardingStatus() {
  let onboardingStatus = localStorage.getItem(LOCAL_STORAGE_ONBOARDING_STATUS_KEY);

  if (onboardingStatus === null || onboardingStatus === undefined) {
    setOnboardingStatus(ONBOARDING_FIRST_TIME_VIEW_STATUS);
    return ONBOARDING_FIRST_TIME_VIEW_STATUS;
  }

  return onboardingStatus;
}