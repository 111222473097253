import {client} from "../../index";
import {AxiosError, AxiosResponse} from "axios";

export function findStories(x1: number, x2: number, y1: number, y2: number) {
  return client
    .get(`/v1/map/stories?x1=${x1}&y1=${y1}&x2=${x2}&y2=${y2}`)
    .then((r) => r.data)
    .catch((e: Error) => e);
}

export function getStory(id: number) {
  return client
    .get(`/v1/map/stories/${id}`)
    .then((r: AxiosResponse) => r);
}

export function storyLike(storyId: number) {
  return client
    .get(`/v1/map/stories/${storyId}/like`)
    .then((r) => r.data)
    .catch((e: Error) => e);
}

export function storyDislike(storyId: number) {
  return client
    .get(`/v1/map/stories/${storyId}/dislike`)
    .then((r) => r.data)
    .catch((e: Error) => e);
}