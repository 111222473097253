import {useContext, useEffect, useState} from "react";
import classNames from "classnames";
import styles from './Header.module.css';
import burgerMenu from '../../img/burger-menu.svg'
import headerLogo from '../../img/logo.png'
import logo from '../../img/logo.png'
import support from '../../img/support.svg'
import megaphone from '../../img/megaphone.svg'
import about from '../../img/demo-info.svg'
import miniMarker from '../../img/marker.svg'
import filledMarker from '../../img/filled-marker.svg'
import closeMenu from '../../img/close-menu.svg'
import locationPicture from '../../img/location.svg'
import close from "../../img/close-blue.svg";
import copy from "../../img/copy-blue.svg";
import share from "../../img/share-blue.svg";
import like from "../../img/like.svg";
import dislike from "../../img/dislike.svg";
import closeRed from "../../img/close-red.svg";
import view from "../../img/view.svg";
import {ModalContext} from "../../../model/context";
import {GEO_LOCATION_MODAL, prepareModal, SHARE_STORY_MODAL, SUPPORT_REQUEST_MODAL} from "../../../model/modal";
import {useLocation, useNavigate} from "react-router-dom";
import {ABOUT_LOCATION, getPage, ROOT_LOCATION} from "../../../model/route";

export const Header = () => {
  let navigate = useNavigate();

  let location = useLocation();
  let currentPath = location.pathname;

  let [, setModalContext] = useContext(ModalContext);
  let [isBurgerMenuVisible, setBurgerMenuVisible] = useState(false);
  let [isLocationButtonVisible, setLocationButtonVisible] = useState(false);

  let handleBurgerMenuClick = () => {
    setBurgerMenuVisible(!isBurgerMenuVisible)
  }

  let handleShowCommonModal = (modal: string) => {
    setBurgerMenuVisible(false);
    setModalContext(prepareModal(modal));
  }

  useEffect(() => {
    const pictures = [megaphone, miniMarker, support, about, closeMenu, burgerMenu, close, copy, share, logo, like, dislike, closeRed, view];
    pictures.forEach((picture) => {
      const img = new Image();
      img.src = picture;
      console.debug(`Preload picture: ${picture}'`);
    });
  }, [])

  useEffect(() => {
    let currentPage = getPage(currentPath);

    if (currentPage === ABOUT_LOCATION) {
      setLocationButtonVisible(false);
    } else {
      setLocationButtonVisible(true);
    }

  }, [location, currentPath]);

  let toPage = (path: string) => {
    setBurgerMenuVisible(false);
    navigate(path);
  }

  return (
    <div className={styles.GenericHeaderContainer}>
      <div className={classNames('d-flex', 'flex-row', 'align-items-center', 'justify-content-between')} style={{ height: '100%' }}>
        <div className={styles.HeaderBurgerBlock} onClick={handleBurgerMenuClick}>
          <div style={{ width: '35px' }}>
            <img src={closeMenu} style={{display: isBurgerMenuVisible ? 'initial' : 'none', width: '19px', marginLeft: '12px', marginTop: '-5px'}} alt={'Close Menu'}/>
            <img src={burgerMenu} style={{display: !isBurgerMenuVisible ? 'initial' : 'none', width: '28px', marginLeft: '7px'}} alt={'Burger Menu'}/>
          </div>
        </div>

        <div className={classNames('d-flex', 'flex-row', 'align-items-center' , styles.HeaderLogoContainer)}>
          <div style={{width: "fit-content", marginLeft: '-9px'}}>
            <img src={headerLogo} alt={'Logo'} style={{ width: '22px' }}/>
          </div>
          <div>
            <div className={styles.HeaderLogoTextBlock}>
              <span className={'raleway'} style={{fontWeight: 'bold', color: 'white', fontSize: '18px', letterSpacing: '0.26em'}}>UTALES</span>
            </div>
          </div>
        </div>

        <div className={styles.HeaderActionRightBlock} onClick={() => isLocationButtonVisible ? handleShowCommonModal(GEO_LOCATION_MODAL) : toPage(ROOT_LOCATION)}>
          <div style={{marginRight: '6px'}}>
          <div style={{borderRadius: '50px', border: '1px solid rgba(233, 228, 228, 0.06)', padding: '7px 10px'}}>
            <img src={locationPicture} style={{width: '17px'}}/>
          </div>
        </div>
    </div>

    <div className={classNames('d-flex', 'flex-row', 'align-items-center', styles.HeaderLinkBlock)} >
      <div
        className={classNames('d-flex', 'flex-row', 'align-items-center', 'pointer')}
        onClick={() => toPage(ROOT_LOCATION)}>
        <div>
          <img src={miniMarker} style={{width: '18px', marginRight: '9px', marginTop: '-4px'}} alt={'Mini Marker'}/>
        </div>
        <div>
          <span className={classNames('raleway', 'bold')} style={{color: 'white', fontSize: '16px', cursor: 'pointer'}}>Главная</span>
        </div>
      </div>
      <div style={{ marginLeft: '54px' }}
           className={classNames('d-flex', 'flex-row', 'align-items-center', 'pointer')}
           onClick={() => {handleShowCommonModal(SHARE_STORY_MODAL)}}>
        <div>
          <img src={megaphone} style={{width: '26px', marginRight: '10px', marginTop: '-5px'}} alt={'Megaphone'}/>
        </div>
        <div>
          <span className={classNames('raleway', 'bold')} style={{color: '#ffffff'}}>Поделиться историей</span>
        </div>
      </div>
      <div style={{ marginLeft: '50px' }} className={classNames('d-flex', 'flex-row', 'align-items-center', 'pointer')} onClick={() => toPage(ABOUT_LOCATION)}>
        <div>
          <img src={about} style={{width: '21px', marginRight: '9px', marginTop: '-5px'}} alt={'About'}/>
        </div>
        <div>
          <span className={classNames('raleway', 'bold')} style={{ color: '#ffffff' }}>О проекте</span>
        </div>
      </div>
    </div>

    <div className={classNames('d-flex', 'flex-row', 'align-items-center', styles.HeaderLoginBlock)}>
      <div
        className={classNames('d-flex', 'align-items-center', 'pointer')}
        onClick={() => {handleShowCommonModal(SUPPORT_REQUEST_MODAL)}}>
        <div>
          <img src={support} style={{width: '24px', marginTop: '-1px'}}/>
        </div>
        <div style={{ marginLeft: '11px'}}>
          <span className="raleway bold" style={{color: '#ffffff'}}>Поддержка</span>
        </div>
      </div>
    </div>

    </div>

      <div style={{ position: 'relative', display: isBurgerMenuVisible ? 'initial' : 'none' }}>
        <div className={styles.HeaderBurgerMenuBlock}>
          <div style={{borderBottom: '1px solid #5C5C5C', paddingTop: '8px', paddingBottom: '8px'}} onClick={() => toPage(ROOT_LOCATION)}>
            <div className={'inline'} style={{marginLeft: '13px'}}>
              <img src={filledMarker} style={{width: '19px', marginTop: '-2px'}} />
            </div>
            <div className={'inline'}>
              <span className={classNames('bold', 'fs-14')} style={{color: '#ffffff', marginLeft: '14px'}}>Главная</span>
            </div>
          </div>
          <div style={{borderBottom: '1px solid #5C5C5C', paddingTop: '8px', paddingBottom: '8px'}} onClick={() => {handleShowCommonModal(SHARE_STORY_MODAL)}}>
            <div className={'inline'} style={{marginLeft: '10px'}}>
              <img src={megaphone} style={{width: '24px'}} />
            </div>
            <div className={'inline'}>
                <span className={classNames('bold', 'fs-14')} style={{color: '#ffffff', marginLeft: '12px'}}>
                    Поделиться историей
                </span>
            </div>
          </div>
          <div style={{borderBottom: '1px solid #5C5C5C', paddingTop: '8px', paddingBottom: '8px', borderBottomRightRadius: '8px'}} onClick={() => toPage(ABOUT_LOCATION)}>
            <div className={'inline'} style={{marginLeft: '12px'}}>
              <img src={about} style={{width: '21px', marginTop: '-2px'}}/>
            </div>
            <div className={'inline'}>
              <span className={classNames('bold', 'fs-14')} style={{color: '#ffffff', marginLeft: '13px'}}>О проекте</span>
            </div>
          </div>
          <div
            style={{borderBottom: '1px solid #5C5C5C', paddingTop: '8px', paddingBottom: '8px', borderBottomRightRadius: '8px'}}
            onClick={() => {handleShowCommonModal(SUPPORT_REQUEST_MODAL)}}>
            <div className={'inline'} style={{marginLeft: '12px'}}>
              <img src={support} style={{width: '22px'}}/>
            </div>
            <div className={'inline'}>
              <span className={classNames('bold', 'fs-14')} style={{color: '#ffffff', marginLeft: '12px'}}>Поддержка</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}