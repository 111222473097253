export const OPEN_MAIN_PAGE_EVENT = 'open_main_page';
export const OPEN_STORY_MODAL_BY_MARKER_EVENT = 'open_story_modal_by_marker';
export const OPEN_ABOUT_PAGE_EVENT = 'open_about_page';
export const OPEN_SHARE_STORY_MODAL_EVENT = 'open_share_story_modal';
export const OPEN_SUPPORT_MODAL_EVENT = 'open_support_modal';
export const ZOOM_IN_ACTION_EVENT = 'zoom_in_action';
export const ZOOM_OUT_ACTION_EVENT = 'zoom_out_action';
export const OPEN_LOCATION_MODAL_EVENT = 'open_location_modal';
export const LOCATION_ACTION_RECOGNIZE_EVENT = 'location_action_recognize';
export const LOCATION_ACTION_SELECT_CITY_EVENT = 'location_action_select_city';
export const LOCATION_ACTION_SELECT_CITY_DONE_EVENT = 'location_action_select_city_done';
export const MODAL_CARD_COPY_ACTION_EVENT = 'modal_card_copy_action';
export const MODAL_CARD_SHARE_ACTION_EVENT = 'modal_card_share_action';
export const CREATE_STORY_ACTION_EVENT = 'create_story_action';
export const SUPPORT_REQUEST_ACTION_EVENT = 'support_request_action';
export const SEND_FEEDBACK_ACTION_EVENT = 'send_feedback_action';
export const CLOSE_LOCATION_MODAL_EVENT = 'close_location_modal';
export const CLOSE_SUPPORT_MODAL_EVENT = 'close_support_modal';
export const CLOSE_SHARE_STORY_MODAL_EVENT = 'close_share_story_modal';
export const CLOSE_STORY_MODAL_EVENT = 'close_story_modal';
export const GREETINGS_SELECT_CITY_EVENT = 'greetings_select_city_event';