import {CONFIG_KEY_DOMAIN, getConfig} from "../configuration";
import axios from "axios";
import {getToken} from "../authorization";

export function prepareAxiosInstance() {
  const domain = getConfig(CONFIG_KEY_DOMAIN);
  const instance = axios.create({
    baseURL: domain,
  });

  instance.interceptors.request.use(
    request => {
      const token = getToken();
      if (token) {
        request.headers.Authorization = token
      }
      return request
    },
    error => {
      return Promise.reject(error)
    }
  )

  return instance;
}