import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css'
import App from './components/App';
import {BrowserRouter} from "react-router-dom";
import {prepareAxiosInstance} from "./service/client/rest-client";
import moment from "moment";
import 'moment/locale/ru'
import {fromLonLat} from "ol/proj";

const instance = prepareAxiosInstance();
export { instance as client };

moment.locale("RU");

// @ts-ignore
window.fromLonLat = fromLonLat

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);