import cities from './data/russian_cities.json';
import exp from "constants";

export interface City {
  value: CityValue
  label: string,
  lat: string,
  lon: string
}

export interface CityValue {
  id: number,
  name: string
}

export function getAllRussianCities() {
  return cities;
}

export function getDefaultCity() {
  return getAllRussianCities()[0];
}