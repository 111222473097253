import {SupportRequestModal} from "./SupportRequestModal/SupportRequestModal";
import {useContext, useEffect} from "react";
import {ModalContext} from "../../model/context";
import {
  GEO_LOCATION_MODAL,
  GREETINGS_MODAL,
  SHARE_STORY_MODAL,
  STORY_MODAL,
  SUPPORT_REQUEST_MODAL
} from "../../model/modal";
import {ShareStoryModal} from "./ShareStoryModal/ShareStoryModal";
import {GreetingsModal} from "./GreetingsModal/GreetingsModal";
import {GeoLocationModal} from "./GeoLocationModal/GeoLocationModal";
import {StoryModal} from "./StoryModal/StoryModal";
import {prepareLocation} from "../../model/location";

export const ModalHub = (props: any) => {
  let [modalContext, setModalContext] = useContext(ModalContext);

  let hideModal = () => {
    setModalContext({modal: [], updateTime: Date.now()})
  }

  let isModalShow = (modal: string) => {
    return modalContext.modal.indexOf(modal) > -1;
  }

  return (
    <>
      <SupportRequestModal
        identifier="support-request-modal"
        backdrop="static"
        show={isModalShow(SUPPORT_REQUEST_MODAL)}
        onHide={hideModal} />

      <ShareStoryModal
        identifier="share-story-modal"
        backdrop="static"
        show={isModalShow(SHARE_STORY_MODAL)}
        onHide={hideModal} />

      <GreetingsModal
        identifier="greetings-modal"
        backdrop="static"
        show={isModalShow(GREETINGS_MODAL)}
        onHide={hideModal} />

      <GeoLocationModal
        identifier="geo-location-modal"
        backdrop="static"
        show={isModalShow(GEO_LOCATION_MODAL)}
        onHide={hideModal} />

      <StoryModal
        identifier="story-modal"
        backdrop="static"
        show={isModalShow(STORY_MODAL)}
        data={modalContext.data}
        onHide={hideModal} />
    </>
  );
}