import styles from './About.module.css';
import logo from '../../img/logo.png'
import classNames from "classnames";
import {Spinner, Toast, ToastContainer} from "react-bootstrap";
import {sendFeedback} from "../../../service/client/feedback-service";
import {useRef, useState} from "react";
import {sendStatsEvent} from "../../../service/client/stats-service";
import {SEND_FEEDBACK_ACTION_EVENT} from "../../../model/event";

export const About = () => {
  let [isButtonPreloaderActive, setButtonPreloaderActive] = useState<boolean>(false);
  const [show, setShow] = useState(false);

  const inputRef = useRef<any>();

  let handleSendFeedback = () => {
    const text = inputRef.current.value;

    if (!isButtonPreloaderActive && text && text.length > 1) {
      setButtonPreloaderActive(true);
      sendFeedback(text)
        .then((r) => console.log('[utales] feedback successfully send'))
        .catch((e) => console.error('[utales] Error while for send feedback'))
        .finally(() => setTimeout(() => {
          setButtonPreloaderActive(false);
          setShow(true);
        }, 1300));
    }
    sendStatsEvent(SEND_FEEDBACK_ACTION_EVENT);
  }
  return (
    <div className={styles.AboutMainContainer}>
      <ToastContainer className={styles.SendFeedbackToast}>
        <Toast onClose={() => setShow(false)} show={show} delay={3000} bg={'dark'} animation={true} autohide>
          <Toast.Body style={{color: '#FFFFFF'}} className={'raleway'}>Спасибо, что делаете наш сайт лучше!</Toast.Body>
        </Toast>
      </ToastContainer>
      <div className={styles.AboutBlock}>
        <div className={styles.AboutContainer}>
          <div style={{paddingTop: '15px', textAlign: 'center', margin: '0 auto', marginLeft: '-17px'}}>
            <div className={classNames('d-flex', 'flex-row', 'align-items-center', 'justify-content-center')}>
              <div>
                <img src={logo} style={{width: '22px', margin: '0 auto'}} alt={'Utales'}/>
              </div>
              <div style={{marginLeft: '16px', marginTop: '4px'}}>
                <span className={'raleway'} style={{fontWeight: 'bold', color: '#000000', fontSize: '17px', letterSpacing: '0.26em'}}>UTALES</span>
              </div>
            </div>

          </div>
          <div className={styles.AboutTextBlock}>
            <div className={classNames(styles.AboutTitleContainer, 'mt-16')}>
              <p className="raleway fs-15"><b>UTALES</b> - это онлайн-карта мира с историями реальных людей</p>
            </div>

            <div className="mt-12">
              <p className="raleway fs-15" style={{textAlign: 'justify'}}><b>Мы</b> как авторы проекта, хотели воплотить в жизнь свою давнюю идею -
                определить на карте мира самые интересные, веселые, мрачные и “грешные” истории с разных углоков нашей
                планеты</p>
            </div>

            <div className="mt-16">
              <p className="raleway fs-15"><b>Мы</b> рады послушать именно вашу историю</p>
            </div>

            <div className={styles.TellMeSomethingContainer}>
              <p className="raleway"><b>Здесь вы можете поделиться с другими всем тем, что давно скрывали или
                хотели рассказать</b></p>
            </div>

            <div style={{marginTop: '28px', textAlign: 'justify'}} >
              <p className="raleway fs-15"><b>Мы</b> рады каждому человеку который зашел на наш ресурс, надеюсь что и мы
                понравимся вам</p>
            </div>

            <div className="mt-16">
              <p className="raleway fs-15" style={{textAlign: 'justify'}}><b>Наш</b> проект простой, притягательный с мелкой щепоткой креатива и
                снобизма - а его пользователи большой дружный клан думающих и веселых людей, которых мы любим и ценим
              </p>
            </div>

            <div className="mt-16">
              <p className="raleway fs-15"><b>Мы</b> не преследуем коммерческих целей, нам нет нужды до ваших
                персональных данных</p>
            </div>

            <div style={{ marginTop: '28px' }}>
              <p className="raleway fs-15 center-text">
                <b>Мы</b> приветствуем любые идеи, критику и пожелания в наш адрес
              </p>
            </div>

            <div style={{textAlign: 'center', marginTop: '31px'}}>
              <p className="bold fs-18 raleway">Напишите нам</p>
            </div>

            <div style={{margin: '0 auto', textAlign: 'center'}}>
              <textarea ref={inputRef} className={styles.TextareaWrapper} placeholder="..."></textarea>
            </div>

            <div style={{textAlign: 'center', marginTop: '15px', paddingBottom: '25px'}}>
              <button className="primary-action-button" onClick={handleSendFeedback}>
                {isButtonPreloaderActive &&
                  <Spinner animation="border" variant="light" size={'sm'} style={{ width: '14px', height: '14px', marginTop: '2px'}} />
                }
                {!isButtonPreloaderActive &&
                  <>Отправить</>
                }
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}