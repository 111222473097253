import {matchPath} from "react-router-dom";

export const ROOT_LOCATION = '/';
export const MAP_LOCATION = '/map';
export const ABOUT_LOCATION = '/about';

export function getPage(currentPath: string) {
  if (matchPath(ROOT_LOCATION, currentPath)) {
    return ROOT_LOCATION;
  }

  if (matchPath(MAP_LOCATION, currentPath)) {
    return MAP_LOCATION;
  }

  if (matchPath(ABOUT_LOCATION, currentPath)) {
    return ABOUT_LOCATION;
  }

  return ROOT_LOCATION;
}