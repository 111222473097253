import {Modal} from "react-bootstrap";
import styles from "../GeoLocationModal/GeoLocation.module.css";
import classNames from "classnames";
import location from "../../img/location.svg";
import locationBlue from "../../img/location-blue.svg";
import city from "../../img/city.svg";
import close from "../../img/close-white.svg";
import React, {useContext, useEffect, useState} from "react";
import Select from "react-select";
import {ApplicationContext} from "../../../model/context";
import {getLocation} from "../../../model/location";
import {City, getDefaultCity} from "../../../model/city";
import {getAllCities} from "../../../service/client/cities-service";
import Skeleton from "react-loading-skeleton";
import {fromLonLat} from "ol/proj";
import {isShow} from "../../../model/modal";
import {sendStatsEvent} from "../../../service/client/stats-service";
import {
  CLOSE_LOCATION_MODAL_EVENT,
  LOCATION_ACTION_RECOGNIZE_EVENT, LOCATION_ACTION_SELECT_CITY_DONE_EVENT,
  LOCATION_ACTION_SELECT_CITY_EVENT,
  OPEN_LOCATION_MODAL_EVENT
} from "../../../model/event";

export const GeoLocationModal = (props: any) => {
  let [applicationContext, setApplicationContext] = useContext(ApplicationContext);

  let [isCitySelectVisible, setCitySelectVisible] = useState(false);
  let [isCitySelectLoading, setCitySelectLoading] = useState(false);

  let showCitySelect = () => {
    setCitySelectLoading(true);
    setCitySelectVisible(true);
    sendStatsEvent(LOCATION_ACTION_SELECT_CITY_EVENT);
  }

  let [selectedCity, setSelectedCity] = useState<City>(getDefaultCity())
  let [options, setOptions] = useState([]);

  let onOptionCitiesChange = (data: any) => {
    setSelectedCity(data);
  }

  let handleCloseModalDialog = () => {
    sendStatsEvent(CLOSE_LOCATION_MODAL_EVENT);
    setTimeout(() => setCitySelectVisible(false), 300);
    props.onHide();
  };

  let handleDoneClick = () => {
    setTimeout(() => setCitySelectVisible(false), 300);

    let context = {...applicationContext}
    context.location = {
      default: false,
      updatedTime: Date.now(),
      zoom: 12,
      user: fromLonLat([Number(selectedCity.lon), Number(selectedCity.lat)])
    };

    setApplicationContext(context);

    sendStatsEvent(LOCATION_ACTION_SELECT_CITY_DONE_EVENT);

    props.onHide();
  }

  let recognizeLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(positionSuccessCallback, positionErrorCallback);
    } else {
      console.error('Something error while for recognize location')
    }
    sendStatsEvent(LOCATION_ACTION_RECOGNIZE_EVENT);
  }

  let positionSuccessCallback = (position: any) => {
    const recognizedLocation = getLocation(position);

    let context = {...applicationContext}
    context.location = recognizedLocation;

    setApplicationContext(context);

    props.onHide();
  }

  let positionErrorCallback = (error: any) => {
    console.log(error);
    props.onHide();
  }

  useEffect(() => {
    if (isShow(props)) {
      sendStatsEvent(OPEN_LOCATION_MODAL_EVENT)
    }
  }, [props.show]);

  useEffect(() => {
    if (isCitySelectVisible) {
      getAllCities()
        .then((data: any) => {
          setOptions(data.map((city: any) => {
            let processedCity: City = {
              lon: city.lon,
              lat: city.lat,
              label: city.label,
              value: {id: city.id, name: city.label}
            };
            return processedCity;
          }));
        })
        .catch((e: Error) => console.log(e))
        .finally(() => setCitySelectLoading(false));
    }

  }, [isCitySelectVisible])

  return (
    <Modal
      {...props}
      aria-labelledby={props.identifier}
      dialogClassName={styles.GeoLocationModalDialog}
      contentClassName={styles.GeoLocationModalDialogContent}
    >
      <Modal.Title className={classNames(styles.GeoLocationModalDialogTitle)} id={props.identifier}>
        <div className={classNames('d-flex', 'flex-row', 'justify-content-between')}>
          <div className={classNames('d-flex', 'flex-row')}>
            <div>
              <img src={location} style={{width: '20px', marginTop: '2px', marginLeft: '12px'}} alt={'Location'}/>
            </div>
            <div>
              <p style={{ fontSize: '13px', fontWeight: 'bold', color: '#ffffff', marginLeft: '11px', marginTop: '11px'}}>Местоположение</p>
            </div>
          </div>
          <div className={classNames('inline', 'pointer')} style={{color: 'white', marginRight: '7px', marginTop: '6px'}}>
            <div style={{borderRadius: '25px', background: 'rgba(196, 196, 196, 0.08)', padding: '6px 10px 6px 10px'}} onClick={handleCloseModalDialog}>
              <div style={{fontSize: '12px'}}>
                <img src={close} style={{marginTop: '-2px'}} alt={'Close'}/>
              </div>
            </div>
          </div>
        </div>
      </Modal.Title>

      <Modal.Body style={{ padding: '0px' }}>
        <div style={{display: isCitySelectVisible ? 'none' : 'initial', textAlign: 'center'}}>
          <div style={{padding: '9px 11px 0px 11px'}}>
            <div>
              <p className={'fs-13'}>
                <span>Определить <span style={{fontWeight: 500}}>местоположение</span> или <span style={{fontWeight: 500}}>выбрать город?</span></span>
              </p>
            </div>
          </div>

          <div className={classNames('d-flex', 'flex-row', 'justify-content-center')} style={{marginTop: '23px', margin: '0 auto', paddingBottom: '12px'}}>
            <button className={'secondary-action-button'} onClick={recognizeLocation}>
              <div className={classNames('d-flex', 'flex-row')}>
                <div>
                  <img src={locationBlue} style={{width: '14px', marginTop: '2px'}} alt={'Location'}/>
                </div>
                <div style={{marginLeft: '6px', marginTop: '2px', fontWeight: 500}}>
                  <div>Местоположение</div>
                </div>
              </div>
            </button>

            <button className={'secondary-action-button'} style={{ marginLeft: '5px'}} onClick={showCitySelect}>
              <div className={classNames('d-flex', 'flex-row')}>
                <div>
                  <img src={city} style={{width: '20px'}} alt={'City'}/>
                </div>
                <div style={{marginLeft: '6px', marginTop: '2px', fontWeight: 500}}>
                  <div>Выбрать город</div>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div style={{ display: isCitySelectVisible ? 'initial' : 'none' }}>
          {isCitySelectLoading &&
            <div style={{ marginTop: '15px', textAlign: 'center', paddingBottom: '15px' }}>
              <Skeleton count={1} style={{width: '240px', height: '34px', margin: '0 auto' }} />
              <Skeleton count={1} borderRadius={15} style={{width: '143px', height: '25px', marginTop: '14px' }} />
            </div>
          }
          {!isCitySelectLoading &&
            <>
              <div style={{marginTop: '15px'}}>
                <div className={styles.GeoLocationSelectContainer}>
                  <Select
                      onChange={onOptionCitiesChange}
                      defaultValue={options[0]}
                      options={options}
                  />
                </div>
              </div>
              <div style={{marginTop: '16px', textAlign: 'center', paddingBottom: '12px'}}>
                <button className={'secondary-action-button'} onClick={handleDoneClick}>
                  <div style={{paddingLeft: '40px', paddingRight: '40px'}}>
                      OK
                  </div>
                </button>
              </div>
            </>
          }
        </div>
      </Modal.Body>
    </Modal>
  );
}