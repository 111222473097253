import styles from './GeoMapNavigation.module.css';
import classNames from "classnames";
import location from "../../img/location.svg";

export const GeoMapNavigation = (props: any) => {
  return (
    <>
      <div className={styles.NavigationButtonContainer}>
        <div>
          <button className={classNames('primary-action-button', styles.ZoomInButton)} onClick={props.out}>+</button>
        </div>
        <div style={{marginTop: '2px'}}>
          <button className={classNames('primary-action-button', styles.ZoomOutButton)} onClick={props.in}>-</button>
        </div>
      </div>
      <div className={styles.LocationButtonContainer}>
        <button className={classNames('primary-action-button', styles.LocationButton)} onClick={props.location}>
          <img src={location} alt={'Location'}/>
        </button>
      </div>
    </>
  );
}