import React, {useEffect, useState} from "react";
import {Header} from "../common/Header/Header";
import {ApplicationContext, ModalContext} from "../../model/context";
import {ModalHub} from "../modals/ModalHub";
import {getOnboardingStatus, ONBOARDING_FIRST_TIME_VIEW_STATUS} from "../../service/onboarding";
import {clearedModal, GREETINGS_MODAL, prepareModal, STORY_MODAL} from "../../model/modal";
import {GeoMap} from "./GeoMap/GeoMap";
import {useLocation, useSearchParams} from "react-router-dom";
import {ABOUT_LOCATION, getPage, MAP_LOCATION, ROOT_LOCATION} from "../../model/route";
import {About} from "./About/About";
import {prepareLocation} from "../../model/location";
import {initializeAccount} from "../../service/client/account-service";
import {STORY_PARAM} from "../../model/url";
import {getCurrentBrowserFingerPrint} from "@rajesh896/broprint.js";
import {setFingerprint, UNRECOGNIZED_FINGERPRINT} from "../../service/identity";
import {sendStatsEvent} from "../../service/client/stats-service";
import {OPEN_ABOUT_PAGE_EVENT, OPEN_MAIN_PAGE_EVENT} from "../../model/event";
import {fromLonLat} from "ol/proj";

export const Main = () => {
  let [page, setPage] = useState('');
  let [isPageLoaded, setPageLoaded] = useState<boolean>(false);
  let [modalContext, setModalContext] = useState(clearedModal());
  let [applicationContext, setApplicationContext] = useState(prepareLocation());

  let [searchParams, ] = useSearchParams();

  let location = useLocation();
  let currentPath = location.pathname;

  /* Отправка статистики после рендера страницы */

  let sendPageStats = (currentPath: string, storyId?: string) => {
    if (!isPageLoaded && (currentPath === ROOT_LOCATION || currentPath === MAP_LOCATION)) {
      sendStatsEvent(OPEN_MAIN_PAGE_EVENT, storyId);
    }

    if (currentPath === ABOUT_LOCATION) {
      sendStatsEvent(OPEN_ABOUT_PAGE_EVENT);
    }
  }

  let showGreetingsModal = () => {
    setModalContext(prepareModal(GREETINGS_MODAL));
  }

  let showStoryModal = (storyId: number) => {
    setModalContext(prepareModal(STORY_MODAL, {id: storyId}));
  }

  let initialPage = () => {
    let currentPage = getPage(currentPath);
    let storyId = searchParams.get(STORY_PARAM);

    setPage(currentPage);

    if ((currentPage === ROOT_LOCATION || currentPage === MAP_LOCATION) && !storyId) {
      let onboardingStatus = getOnboardingStatus();
      if (onboardingStatus === ONBOARDING_FIRST_TIME_VIEW_STATUS) {
        setTimeout(showGreetingsModal, 400);
      }
    }

    if ((currentPage === ROOT_LOCATION || currentPage === MAP_LOCATION) && storyId) {
      setTimeout(() => showStoryModal(Number(storyId)), 200);
    }

    sendPageStats(currentPage, storyId || '');
  }

  useEffect(() => {
    initializeAccount().then((account) => {
      getCurrentBrowserFingerPrint().then((fingerprint) => {
        setFingerprint(fingerprint);
      }).catch((e) => {
        setFingerprint(`${UNRECOGNIZED_FINGERPRINT}_${Date.now()}`)
      }).finally(() => {
        initialPage();
        setPageLoaded(true);
      })
    })
  }, [location, currentPath]);

  return (
    <ApplicationContext.Provider value={[applicationContext, setApplicationContext]}>
      <ModalContext.Provider value={[modalContext, setModalContext]}>
        <div>
          {(page === ROOT_LOCATION || page === MAP_LOCATION) &&
            <div style={{width: '100%', height: '100%', position: 'absolute', zIndex: 1}}>
              <GeoMap/>
            </div>
          }

          <div style={{ zIndex: 999, position: "relative" }}>
            <Header />
          </div>

          {page === ABOUT_LOCATION &&
            <div>
              <About/>
            </div>
          }

          <div>
            <ModalHub />
          </div>
        </div>
      </ModalContext.Provider>
    </ApplicationContext.Provider>
  );
}