import {client} from "../../index";

export function sendFeedback(text: string) {
  return client({
    method: "POST",
    url: "/v1/feedback",
    data: {
      text: text,
    },
  }).then((r) => r).catch((e: Error) => e);
}