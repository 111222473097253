import {LOCAL_STORAGE_FINGERPRINT} from "../model/storage";

export const UNRECOGNIZED_FINGERPRINT = 'unrecognized';

export function setFingerprint(fingerprint: any) {
  if (!getFingerprint()) {
    localStorage.setItem(LOCAL_STORAGE_FINGERPRINT, btoa(fingerprint));
  }
}

export function getFingerprint(): string {
  return localStorage.getItem(LOCAL_STORAGE_FINGERPRINT) || '';
}