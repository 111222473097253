import React, {useEffect} from "react";
import support from '../../img/support.svg'
import telegram from '../../img/telegram.svg'
import close from '../../img/close-white.svg'
import styles from './SupportRequestModal.module.css';
import classNames from "classnames";
import {Modal} from "react-bootstrap";
import {isShow} from "../../../model/modal";
import {sendStatsEvent} from "../../../service/client/stats-service";
import {CLOSE_SUPPORT_MODAL_EVENT, OPEN_SUPPORT_MODAL_EVENT, SUPPORT_REQUEST_ACTION_EVENT} from "../../../model/event";

export const SupportRequestModal = (props: any) => {
  let handleCloseModal = () => {
    sendStatsEvent(CLOSE_SUPPORT_MODAL_EVENT);
    props.onHide();
  }

  let handleSupportRequestClick = () => {
    window.open('https://t.me/utales_bot', '_blank');
    sendStatsEvent(SUPPORT_REQUEST_ACTION_EVENT);
  }

  useEffect(() => {
    if (isShow(props)) {
      sendStatsEvent(OPEN_SUPPORT_MODAL_EVENT)
    }
  });

  return (
    <Modal
      {...props}
      aria-labelledby={props.identifier}
      dialogClassName={styles.SupportRequestModalDialog}
      contentClassName={styles.SupportRequestModalDialogContent}
    >
      <Modal.Title className={classNames(styles.SupportRequestModalDialogTitle)} id={props.identifier}>
        <div className={classNames('d-flex', 'flex-row', 'justify-content-between')}>
          <div className={classNames('d-flex', 'flex-row')}>
            <div>
              <img src={support} style={{width: '27px', marginTop: '5px', marginLeft: '6px'}} alt={'Support'}/>
            </div>
            <div>
              <p style={{ fontSize: '13px', fontWeight: 'bold', color: '#ffffff', marginLeft: '8px', marginTop: '11px'}}>Обращение в поддержку</p>
            </div>
          </div>
          <div className={classNames('inline', 'pointer')} style={{color: 'white', marginRight: '7px', marginTop: '6px'}}>
            <div style={{borderRadius: '25px', background: 'rgba(196, 196, 196, 0.08)', padding: '6px 10px 6px 10px'}} onClick={handleCloseModal}>
              <div style={{fontSize: '12px'}}>
                <img src={close} style={{marginTop: '-2px'}} />
              </div>
            </div>
          </div>
        </div>
      </Modal.Title>
      <Modal.Body style={{ padding: '0px' }}>
        <div style={{textAlign: 'center', paddingBottom: '16px'}}>
          <div style={{padding: '9px 11px 0px 11px'}}>
            <div>
              <p className={'fs-13'}>
                Что-то не получилось или работает не правильно?
              </p>
            </div>
            <div style={{ marginTop: '18px'}}>
              <div style={{width: '250px', margin: '0 auto'}}>
                <p className={'fs-13'}>
                  Напишите нашему Telegram боту и мы постараемся решить проблему
                </p>
              </div>
            </div>
          </div>

          <div>
            <button className={'secondary-action-button'} onClick={handleSupportRequestClick}>
              <div className={classNames('d-flex', 'flex-row')}>
                <div>
                  <img src={telegram} style={{width: '22px'}}/>
                </div>
                <div style={{marginLeft: '6px', marginTop: '2px'}}>
                  <div>Отправить сообщение</div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}