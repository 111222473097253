import React, {useContext, useEffect, useState} from "react";
import {Feature, View} from "ol";
import {Point} from "ol/geom";
import {fromLonLat, toLonLat} from "ol/proj";
import VectorSource from "ol/source/Vector";
import {Cluster, XYZ} from "ol/source";
import VectorLayer from "ol/layer/Vector";
import {Fill, Icon, Stroke, Style, Text} from "ol/style";
import TileLayer from "ol/layer/Tile";
import {boundingExtent} from "ol/extent";
import Map from 'ol/Map';
import {GeoMapNavigation} from "../../common/GeoMapNavigation/GeoMapNavigation";
import {GEO_LOCATION_MODAL, prepareModal, STORY_MODAL} from "../../../model/modal";
import {ApplicationContext, ModalContext} from "../../../model/context";
import {useLocation, useSearchParams} from "react-router-dom";
import {findStories} from "../../../service/client/stories-service";
import {Story} from "../../../model/story";
import {getLastCoordinates, getZoom, setLastCoordinates, setZoom} from "../../../model/map";
import {STORY_PARAM} from "../../../model/url";
import {sendStatsEvent} from "../../../service/client/stats-service";
import {OPEN_SUPPORT_MODAL_EVENT, ZOOM_IN_ACTION_EVENT, ZOOM_OUT_ACTION_EVENT} from "../../../model/event";
import {fromString} from "ol/color";

export const GeoMap = () => {
  let [applicationContext, ] = useContext(ApplicationContext);
  let [, setModalContext] = useContext(ModalContext);

  let [, setSearchParams] = useSearchParams();

  let location = useLocation();
  let currentPath = location.pathname;

  let [openlayerMap, setOpenlayerMap] = useState<Map>(new Map({}));
  let [openlayerMapSource, setOpenlayerMapSource] = useState<any>({});

  let handleInitializeMap = () => {
    console.log("initialize")
    const source = new VectorSource({
      features: [],
    });

    const clusterSource = new Cluster({
      distance: 45,
      minDistance: 10,
      source: source
    });

    const styleCache = {};

    const clusters = new VectorLayer({
      updateWhileInteracting: true,
      source: clusterSource,
      style: function (feature) {
        const size = feature.get('features').length;
        // @ts-ignore
        let style = styleCache[size];

        style = new Style({
          image: new Icon({
            crossOrigin: 'anonymous',
            src: '/marker.svg'
          }),
          // @ts-ignore
          text: new Text({
            // @ts-ignore
            text: feature.values_.features[0]["viewsCount"],
            font: 'bold 11px Inter',
            padding: [2, 8, 2, 8],
            backgroundFill: new Fill({
              color: '#FFF',
            }),
            backgroundStroke: new Stroke({
              color: '#000',
              width: 1,
              lineCap: "round"
            }),
            fill: new Fill({
              color: '#000',
            }),
            offsetY: -27,
            offsetX: -1
          }),
        });
        // @ts-ignore
        styleCache[size] = style;
        return style;
      },
    });

    let map = new Map({
      // @ts-ignore
      target: 'map',
      layers: [
        new TileLayer({
          // preload: Infinity, подумать, насколько это нужно, т.к. трафика в х4 раза больше чем при дефолтном значении пропса
          source: new XYZ({
            url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          })
        }),
        clusters
      ],
      view: new View({
        center: getLastCoordinates(),
        zoom: getZoom()
      }),
      controls:[]
    });

    map.on('click', (e) => {
      const hit = map.hasFeatureAtPixel(e.pixel);
      console.log(`Point: [${toLonLat(e.coordinate)}]`)
      console.log(`Coordinates: [${e.coordinate}]`);
      if (hit) {
        clusters.getFeatures(e.pixel).then((clickedFeatures) => {
          let features: any[] = []

          map.forEachFeatureAtPixel(e.pixel, function (feature) {
            features.push(feature)
            return feature;
          });

          if (features.length) {
            const processedFeatures = features[0].get('features')
            if (processedFeatures.length > 1) {
              console.log('more')
              const extent = boundingExtent(
                processedFeatures.map((r: any) => r.getGeometry().getCoordinates())
              );
              map.getView().fit(extent, {duration: 850, padding: [70, 70, 70, 70]});
            } else {
              let storyId = processedFeatures[0].getId();
              setSearchParams((params) => {
                params.set(STORY_PARAM, storyId)
                return params;
              })
              showStoryModal(storyId);
            }
          }
        });
      }

    });

    map.on('pointermove', function(e){
      const pixel = map.getEventPixel(e.originalEvent);
      const hit = map.hasFeatureAtPixel(pixel);
      map.getViewport().style.cursor = hit ? 'pointer' : '';
    });

    setOpenlayerMap(map);
    setOpenlayerMapSource(source);
  }

  let zoomIn = () => {
    let currentZoom = Number(openlayerMap.getView().getZoom());
    openlayerMap.getView().animate({
      zoom: currentZoom - 0.8,
      duration: 200,
    })
    sendStatsEvent(ZOOM_IN_ACTION_EVENT);
  }

  let zoomOut = () => {
    let currentZoom = Number(openlayerMap.getView().getZoom());
    openlayerMap.getView().animate({
      zoom: currentZoom + 0.8,
      duration: 200,
    });
    sendStatsEvent(ZOOM_OUT_ACTION_EVENT);
  }

  let processLocation = () => {
    setModalContext(prepareModal(GEO_LOCATION_MODAL));
  }

  let showStoryModal = (storyId: number) => {
    setModalContext(prepareModal(STORY_MODAL, {id: storyId}));
  }

  useEffect(() => {
    let currentPath = location.pathname;
    handleInitializeMap();
  }, [currentPath, location.pathname])

  useEffect(() => {
    const updatedLocation = applicationContext.location;

    openlayerMap.setView(new View({
      center: updatedLocation.user,
      zoom: updatedLocation.zoom
    }));

    if (updatedLocation && updatedLocation.user) {
      setLastCoordinates(updatedLocation.user);
    }
  }, [applicationContext.location]);

  useEffect(() => {
    openlayerMap.on("moveend", function(e){
      let coordinates = openlayerMap.getView().calculateExtent();
      let x1 = coordinates[0] * 0.964;
      let y1 = coordinates[1] * 0.964;
      let x2 = coordinates[2] * 1.056;
      let y2 = coordinates[3] * 1.056;

      console.log(coordinates)

      findStories(x1, x2, y1, y2)
        .then((stories: Story[]) => {
          openlayerMapSource.clear();
          stories.forEach((story) => {
            let point = new Feature(new Point(fromLonLat([Number(story.lon), Number(story.lat)])));
            // @ts-ignore
            point['viewsCount'] = String(story.views);
            point.setId(story.id);
            openlayerMapSource.addFeature(point);
          })
        })
        .catch((e: Error) => {
          console.log(e);
        });

      let currentZoom = openlayerMap.getView().getZoom() || 13;
      setZoom(currentZoom);
    });
  },[openlayerMapSource])

  return (
    <>
      <GeoMapNavigation in={zoomIn} out={zoomOut} location={processLocation} />
      <div id="map" className="map" style={{height: '100%', width: '100%'}}></div>
    </>
  )
}