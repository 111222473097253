import React from 'react';
import {Route, Routes} from "react-router-dom";
import {Main} from "./page/Main";
import {NotFound} from './page/NotFound/NotFound';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path="/map" element={<Main/>}/>
        <Route path="/map/story/:storyId" element={<Main/>}/>
        <Route path="/about" element={<Main/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </div>
  );
}

export default App;
